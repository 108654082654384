import * as React from 'react'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {  Link } from 'gatsby'
import {Helmet} from "react-helmet";



export default function AMTCE() {
  return (
    <Layout pageTitle="AMTCE">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>AMTCE - USI Projects</title>
        <meta name="description"
          content="Part of a sustainable master plan to regenerate the Portview site."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

       <section class="text-gray-700 body-font heading-container-amtce">
       <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">AMTCE</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                Advanced Manufacturing<br/>Training Centre of Excellence</p>
              </div>
          </section>
        </div>
      </section>

      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 pt-12 lg:pt-24  md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading">OVERVIEW</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl   ">
                  The AMTCE supports people to reskill or upskill in the new emerging technologies used in industry 4.0 or to 
                  enable people who wish to develop new and rewarding careers in advanced manufacturing. 
                  The project involved branding and logo design, web design and web platform development. 
                  </p>
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase  ">Fields of
                  Practice</p>
                <p
                  class="my-4 text-gray-500 leading-relaxed mt-0">Design<br/>
                  Technology<br/>
                </p>
                <p class="text-2xl font-bold uppercase    mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed    mt-0">
                  Branding<br/>Architecture<br/>Web Design<br/>Web Development<br/></p>
                  <p class="text-2xl font-bold uppercase    mt-8">Visit</p>
                <p class="my-4 text-gray-500 leading-relaxed    mt-0">https://amtce.ie</p>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true" class="md:p-16 md:m-16  ">
          <img class="w-screen" src="../img/projects/amtce-under-hero.webp"/>
      </div>

      <div data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true" class="md:p-16 md:m-16  ">
        <h3 class="my-4 text-black text-xl md:text-2xl lg:text-3xl  px-5 md:px-0 w-6/6 md:w-4/6">The AMTCE branding was developed to give the centre a clean and modern look and feel</h3>
        <img class="w-screen" src="../img/projects/AMTCE Logos.png"/>
      </div>

      <div data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true" class="md:py-16 md:my-16  ">
        <img class="w-screen" src="../img/projects/amtce-holding-phone.webp"/>
      </div>

      <div>
        <h3 class="md:p-16 md:m-16 text-black text-xl md:text-2xl lg:text-3xl  px-5 md:px-0 w-6/6 md:w-4/6">Architectural visualisations were developed alongside the branding to create a cohesive look and feel</h3>
        <div class="max-w-full md:flex md:space-x-4 md:pl-16 md:pr-16 pb-4">
          <img class="w-full py-2 md:py-0 md:w-3/6" src="../img/projects/01_stair_final.webp" alt="Stairs"/>
          <img class="w-full md:w-3/6" src="../img/projects/02_reception_final.webp" alt="Reception"/>
        </div>
        <div class="max-w-full md:flex md:space-x-4  md:pl-16 md:pr-16">
           <img class="w-full py-2 md:py-0 md:w-3/6" src="../img/projects/03_kiosk_final.webp" alt="Kiosk"/>
           <img class="w-full md:w-3/6" src="../img/projects/04_social-space_final.webp" alt="Social Space"/>  
        </div>
      </div>

      <div class="py-6">
        <img class="w-screen" src="../img/projects/05_makerspace 1.webp"/>
      </div>

      <div data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true" class="md:py-16 md:my-16 md:pl-16 md:pr-16">
        <h3 class="my-4 text-black text-xl md:text-2xl lg:text-3xl  px-5 md:px-0 w-6/6 md:w-4/6">
        UX, Web Design and development for the public facing AMTCE website. Developed on Wordpress, 
        this website was co-designed along with the stakeholders and end users to ensure it met the needs of trainees and employers. </h3>
        <img src="../img/projects/amtce-final.webp"/>
      </div>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-blue-400">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../whatsthestory" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
